
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
import type { NextPage } from 'next';
import { useEffect } from 'react';
const Home: NextPage = () => {
    const router = useRouter();
    useEffect(() => {
        router.push('/auth/sign-in/bank-id');
    }, [router]);
    return null;
};
export default Home;

    async function __Next_Translate__getStaticProps__1891fecc5c2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1891fecc5c2__ as getStaticProps }
  